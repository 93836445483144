@use '../../../../styles/_global-variables.scss' as global;
@use '../../../../styles/_portal-variables.scss' as tresplay;

.afegeix {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s;
    z-index: 1;
    svg {
        filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.3));
    }
}

.ranking .imatgeWrapper {
    &:before {
        content: '';
        width: 36%;
        padding-top: 20%;
        background: linear-gradient(209deg, rgba(0, 0, 0, 1) 49%, rgba(0, 0, 0, 0) 50%) no-repeat 1px -1px,
            linear-gradient(209deg, rgba(65, 65, 65, 1) 49%, rgba(65, 65, 65, 0) 50%) no-repeat;
        border-top: 1px solid #414141;
        border-right: 1px solid #414141;
        position: absolute;
        top: 0;
        right: 0;
        pointer-events: none;
        z-index: 3;
    }
    &:after {
        content: counter(numeracio_thumbs);
        counter-increment: numeracio_thumbs;
        font-family: 'Roboto Slab', serif;
        font-weight: 700;
        font-size: 4.125rem;
        position: absolute;
        top: -2.8rem;
        right: 5%;
        pointer-events: none;
        z-index: 3;
    }
}

.textWrapper {
    position: absolute;
    z-index: 0;
    top: 100%;
    width: 100%;
    margin-top: 16px;
}

.wrapper {
    display: block;
    width: 100%;
    transition: all 0.2s;
    position: relative;

    .az & {
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.9) 78.65%);
            z-index: 1;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.2s;
        }
        .textWrapper {
            margin-top: 0;
            position: relative;
            padding: 0 16px;
            width: auto;
            z-index: 2;
            display: flex;
            flex-direction: column;
            align-items: baseline;

            .titol {
                font-size: var(--headline_xs);
                line-height: 1.3;
                margin: 8px 0 4px;
                padding: 0;
                opacity: 0;
                transition: all 0.2s;
            }
            .extraInfo {
                margin-bottom: 10px;
                opacity: 0;
                transition: all 0.2s;
            }
            .entradeta {
                margin: 0 0 14px;
                padding-top: 0;
                overflow: hidden;
                -webkit-line-clamp: 6;
            }
        }
    }
}
.wrapperHover {
    @media (hover) {
        &:hover {
            margin: -48px -16px -180px;
            width: calc(
                100% + 32px
            ); //provoca problemes en combinació amb el freemode i el simulatetouch. Mirar si cal o no arreglar-ho.
            position: absolute;
            border: 1px solid var(--clr_trans_80);
            .imatgeWrapper {
                margin-bottom: 14px;
            }
            .textWrapper {
                margin-top: 0;
                position: relative;
                padding: 0 16px;
                width: auto;
                z-index: 2;
                display: flex;
                flex-direction: column;
                align-items: baseline;
            }
            .afegeix {
                opacity: 1;
                pointer-events: all;
            }
            .avantitol {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                order: 1;
            }
            .titol {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                order: 1;
                margin: 8px 0 4px;
            }
            .capitol {
                opacity: 1;
            }
            .extrainfo {
                order: 2;
                opacity: 1;
                transition-property: opacity;
                transition-delay: 0.2s;
                transition-duration: 0.2s;
            }
            .entradeta {
                opacity: 1;
                order: 4;
                margin: 0 0 24px;
                transition-property: opacity;
                transition-delay: 0.2s;
                transition-duration: 0.2s;
            }
            .etiqueta {
                opacity: 0;
            }
            .codiespectador {
                display: inline-block;
                margin-right: 0;
            }
            .durada {
                display: inline-block;
                vertical-align: top;
                font-size: var(--body_s);
                margin-top: 4px;
                height: 16px;
                margin-bottom: 10px;
            }
        }
    }
}

.icona_play {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.4);
    display: grid;
    place-content: center;
    opacity: 0;
    border-radius: 50%;

    .audio {
        margin-left: 0px;
    }

    &:hover {
        background-color: var(--clr_portal_100);
        .play {
            opacity: 1;
        }
    }

    @media #{global.$VP_ALL_MOBILE} {
        opacity: 1;
    }
}

.wrapper:hover {
    .icona_play {
        opacity: 1;
    }
}

.play {
    width: 23px;
    opacity: 0.8;
}

.blurWrapper {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: -24%;
    opacity: 0;
    transition: all 0.2s;
}
.imatgeBlurred {
    filter: blur(10px);
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
}

.quadrat {
    :global {
        .swiper-slide {
            aspect-ratio: 1/1;
        }
    }
    .imatgeWrapper {
        aspect-ratio: 1/1;
        transition: all 0.2s;
        overflow: visible;
    }
    .textWrapper {
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s;
        transition-delay: 0.2s;
    }
    @media (hover) {
        .wrapperHover {
            &:hover {
                display: flex;
                flex-direction: column-reverse;
                background-color: var(--clr_trans_90);
                z-index: 1;
                width: 160%;
                margin-left: -30%;
                .overIcon {
                    display: none;
                }
                .imatgeWrapper {
                    margin: 0 16% 14px;
                }
                .blurWrapper {
                    opacity: 1;
                    width: calc(148% - 2px);
                    margin-left: 1px;
                }
                .textWrapper {
                    opacity: 1;
                    pointer-events: all;
                    position: relative;
                }
            }
        }

        &.dreta {
            .wrapperHover:hover {
                margin: -48px -32px -180px 0;
            }
        }
        &.esquerra {
            .wrapperHover:hover {
                margin: -48px 0 -180px -32px;
            }
        }
    }
}

.imatgeWrapper {
    position: relative;
    background-color: global.$color80;

    display: block;
    aspect-ratio: 1 / 1;
    img {
        transition: opacity 0.5s;
    }
}

.avantitol {
    font-weight: 400;
    text-transform: uppercase;
    font-size: var(--body_xs);
    line-height: 14px;
    display: none;
}
.titol {
    font-size: var(--body_m);
    line-height: 24px;
    font-weight: 500;
    opacity: 1;
    transition: opacity 0.2s;
    transition-delay: 0.2s;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.capitol {
    font-size: var(--body_m);
    font-weight: 400;
    margin-bottom: 6px;
    opacity: 0;
    line-height: 1.3;
    transition: opacity 0.2s;
}
.extrainfo {
    width: 100%;
    margin-bottom: 16px;
    opacity: 0;
    transition-property: opacity;
    transition-delay: 0s;
    transition-duration: 0s;
}
.entradeta {
    font-weight: 400;
    font-size: var(--body_s);
    line-height: 1.5;
    opacity: 0;
    transition-property: opacity;
    transition-delay: 0s;
    transition-duration: 0s;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.imatge {
    width: 100%;
}
.codiespectador {
    display: none;
    width: 20px;
    height: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
}
.infoTitol {
    font-weight: 300;
    font-size: var(--headline_xs);
    line-height: 1.4;
    text-transform: uppercase;
    padding: 0 0 16px 56px;
    pointer-events: all;
    a:hover {
        border-bottom: 1px solid;
    }
}

.overIcon {
    width: 32px;
    height: 32px;
    background-color: rgba(17, 17, 17, 0.5);
    border-radius: 16px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 5px;
    left: 5px;
}
.innerOverIcon {
    width: 20px;
    height: 20px;
}

@media #{global.$VP_MOBILE_AND_TP} {
    .ranking .imatgeWrapper:after {
        font-size: 3rem;
        top: -2rem;
    }
}

@media #{global.$VP_ALL_MOBILE} {
    .rankingMobile .imatgeWrapper {
        &:before {
            content: '';
            opacity: 80%;
            width: 0;
            height: 0;
            border-top: 0px solid transparent;
            border-right: 80px solid #111111;
            border-bottom: 40px solid transparent;
            position: absolute;
            top: 0;
            right: 0;
            pointer-events: none;
            z-index: 3;
        }
        &:after {
            content: counter(numeracio_thumbs);
            counter-increment: numeracio_thumbs;
            font-family: 'Museo Slab 900', 'Roboto Slab', serif;
            position: absolute;
            font-size: 3rem;
            top: -0.25rem;
            right: 8px;
            pointer-events: none;
            z-index: 3;
        }
    }
}

.mosaic {
    :global {
        .no-imatge {
            // width: 100% !important;
            // height: auto !important;

            span {
                width: 100% !important;
                height: auto !important;
            }
        }
    }
}

@media (hover) {
    @media (min-width: 570px) and (max-width: 926px) {
        .poster:not(.az):nth-child(2n):hover {
            .wrapperItemMosaic {
                margin-right: 0;
            }
        }
        .poster:not(.az):nth-child(2n + 1):hover {
            .wrapperItemMosaic {
                margin-left: 0;
            }
        }
    }
}

@media (hover) {
    @media (min-width: 927px) and (max-width: 1210px) {
        .poster:not(.az):nth-child(3n):hover {
            .wrapperItemMosaic {
                margin-right: 0;
            }
        }
        .poster:not(.az):nth-child(3n + 1):hover {
            .wrapperItemMosaic {
                margin-left: 0;
            }
        }
    }
}

@media (hover) {
    @media (min-width: 1211px) and (max-width: 1541px) {
        .poster:not(.az):nth-child(4n):hover {
            .wrapperItemMosaic {
                margin-right: 0;
            }
        }
        .poster:not(.az):nth-child(4n + 1):hover {
            .wrapperItemMosaic {
                margin-left: 0;
            }
        }
    }
}

@media (hover) {
    @media (min-width: 1542px) and (max-width: 1825px) {
        .poster:not(.az):nth-child(5n):hover {
            .wrapperItemMosaic {
                margin-right: 0;
            }
        }
        .poster:not(.az):nth-child(5n + 1):hover {
            .wrapperItemMosaic {
                margin-left: 0;
            }
        }
    }
}

@media (hover) {
    @media (min-width: 1826px) {
        .poster:not(.az):nth-child(6n):hover {
            .wrapperItemMosaic {
                margin-right: 0;
            }
        }
        .poster:not(.az):nth-child(6n + 1):hover {
            .wrapperItemMosaic {
                margin-left: 0;
            }
        }
    }
}

//HOVERS A-Z
@media (hover) {
    @media (min-width: 391px) and (max-width: 680px) {
        .poster.az:nth-child(2n):hover {
            .wrapperItemMosaic {
                margin-right: 0;
                margin-left: -32px;
            }
        }
        .poster.az:nth-child(2n + 1):hover {
            .wrapperItemMosaic {
                margin-left: 0;
                margin-right: -32px;
            }
        }
    }
    @media (min-width: 681px) and (max-width: 932px) {
        .poster.az:nth-child(3n):hover {
            .wrapperItemMosaic {
                margin-right: 0;
                margin-left: -32px;
            }
        }
        .poster.az:nth-child(3n + 1):hover {
            .wrapperItemMosaic {
                margin-left: 0;
                margin-right: -32px;
            }
        }
    }
    @media (min-width: 933px) and (max-width: 1148px) {
        .poster.az:nth-child(4n):hover {
            .wrapperItemMosaic {
                margin-right: 0;
                margin-left: -32px;
            }
        }
        .poster.az:nth-child(4n + 1):hover {
            .wrapperItemMosaic {
                margin-left: 0;
                margin-right: -32px;
            }
        }
    }
    @media (min-width: 1149px) and (max-width: 1364px) {
        .poster.az:nth-child(5n):hover {
            .wrapperItemMosaic {
                margin-right: 0;
                margin-left: -32px;
            }
        }
        .poster.az:nth-child(5n + 1):hover {
            .wrapperItemMosaic {
                margin-left: 0;
                margin-right: -32px;
            }
        }
    }
    @media (min-width: 1365px) and (max-width: 1624px) {
        .poster.az:nth-child(6n):hover {
            .wrapperItemMosaic {
                margin-right: 0;
                margin-left: -32px;
            }
        }
        .poster.az:nth-child(6n + 1):hover {
            .wrapperItemMosaic {
                margin-left: 0;
                margin-right: -32px;
            }
        }
    }
    @media (min-width: 1625px) and (max-width: 1840px) {
        .poster.az:nth-child(7n):hover {
            .wrapperItemMosaic {
                margin-right: 0;
                margin-left: -32px;
            }
        }
        .poster.az:nth-child(7n + 1):hover {
            .wrapperItemMosaic {
                margin-left: 0;
                margin-right: -32px;
            }
        }
    }
    @media (min-width: 1841px) {
        .poster.az:nth-child(8n):hover {
            .wrapperItemMosaic {
                margin-right: 0;
                margin-left: -32px;
            }
        }
        .poster.az:nth-child(8n + 1):hover {
            .wrapperItemMosaic {
                margin-left: 0;
                margin-right: -32px;
            }
        }
    }
}

//FIX SAFARI
@supports not (aspect-ratio: 16 / 9) {
    .imatgeWrapper {
        padding-top: 100%;
    }
    .wrapperHover .imatgeWrapper {
        padding-top: calc(100% / 16 * 11);
    }
}
